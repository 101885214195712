//import logo from "./logo.svg";
import Home from "./Home";

import {
	BrowserRouter,
	Routes,
	Route,
  } from "react-router-dom";

function App() {
  return (
    <>
      {/* <Header></Header> */}
	  <Routes>
		  <Route path="/" element={<Home/>}></Route>
	  </Routes>
	  
    </>
  );
}

export default App;
