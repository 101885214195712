import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n.use(initReactI18next).init({
  resources: {
    English: {
      translation: {
        greeting: "English",
        Home: "Home",
        About: "About",
        Features: "Features",
        How_it_works: "How it works",
        Contact: "Contact",
        Buy_Sell_Find: "Buy, Sell & Find",
        just_about_anything_using: "just about anything using the",
        lorem_ipsum_1: "Lorem Ipsum is simply dummy text of the printing and  typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
        app_your_mobile: "app on your mobile",
        Scroll: "Scroll",
        All_About_Map: "All About On Map",
        A_Brief_Introduction: "A Brief Introduction",
        long_established_fact: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English.",
        Many_desktop_publishing_packages: "Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy.",
        View_Demo: "View Demo",
        What_makes_us: "What makes us",
        unique: "unique",
        from_others: "from others",
        Advanced: "Advanced",
        Search: "Search",
        Filters: "Filters",
        advList1: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit.",
        advList2: "Vivamus vestibulum nulla nec ante",
        advList3: "Integer vitae libero ac risus egestas placerat.",
        advList4: "Vestibulum commodo felis quis tortor.",
        Crazy: "Crazy",
        Discounts: "Discounts",
        Notifications: "Notifications",
        within_given_radius: "within given radius",
        How_it: "How it",
        Works: "Works",
        Search_Ad: "Search Ad",
        Select_Product: "Select Product",
        Chat_with_Seller: "Chat with Seller",
        Buy: "Buy",
        Contact: "Contact",
        Us: "Us",
        First_Name: "First Name",
        Last_Name: "Last Name",
        Email: "Email",
        Phone: "Phone",
        Comments: "Comments",
        Your_message_here: "Your message here",
        Submit: "Submit",
        address: "Ahmad Bin Hanbal Street Ar Rayyan Dist.,Riyadh,Saudi Arabia",
        All_Rights_Reserved: "All Rights Reserved",
        Designed_Developed_by: "Designed and Developed by",
        Fowl: "Fowl",
        
        
        // Add more translations here
      },
    },
    العربية: {
      translation: {
        greeting: "العربية",
        Home: "الرئيسية",
        About: "عن",
        Features: "المميزات",
        How_it_works: "كيف يعمل",
        Contact: "تواصل",
        Buy_Sell_Find: "شراء، بيع، والبحث",
        just_about_anything_using: "تقريبًا أي شيء باستخدام",
        lorem_ipsum_1: "لوريم إيبسوم هو مجرد نص دمية لصناعة الطباعة والتنضيد. لوريم إيبسوم هو النص الدمية القياسي في هذه الصناعة منذ القرن الخامس عشر",
        app_your_mobile: "تطبيق على هاتفك المحمول",
        Scroll: "تمرير",
        All_About_Map: "كل شيء على الخريطة",
        A_Brief_Introduction: "مقدمة مختصرة",
        long_established_fact: "إنه حقيقة مثبتة منذ فترة طويلة أن القارئ سيُشتت انتباهه بمحتوى الصفحة قابل للقراءة عند النظر إلى تصميمها. الهدف من استخدام لوريم إيبسوم هو أن لديه توزيعًا أكثر أو أقل طبيعي للأحرف، بدلاً من استخدام 'محتوى هنا، محتوى هنا'، مما يجعله يبدو كما لو كان إنجليزيًا قابلًا للقراءة.",
        Many_desktop_publishing_packages: "العديد من حزم تنسيق النشر على سطح المكتب ومحرري صفحات الويب يستخدمون الآن لوريم إيبسوم كنص نموذجي افتراضي، وبحث عن 'لوريم إيبسوم' سيكشف عن العديد من مواقع الويب لا تزال في مراحلها الأولى.",
        View_Demo: "عرض العرض التوضيحي",
        What_makes_us: "ما الذي يجعلنا",
        unique: "فريد",
        from_others: "من الآخرين",
        Advanced: "متقدم",
        Search: "بحث",
        Filters: "مرشحات",
        advList1: "لوريم إيبسوم دولور سيت آميت، كونسيكتيتور أديبيسينغ إليت.",
        advList2: "نعيش ممرًا ليس له نهاية قبل.",
        advList3: "عدد صحيح يتضمن الحرية ويتضمن أيضًا مكانًا للتخزين.",
        advList4: "البساطة تجعل الأمور أسهل.",
        Crazy: "مجنونة",
        Discounts: "تخفيضات",
        Notifications: "إشعارات",
        within_given_radius: "ضمن نصف قطر معين",
        How_it: "كيف يتم",
        Works: "يعمل",
        Search_Ad: "إعلان البحث",
        Select_Product: "اختر المنتج",
        Chat_with_Seller: "الدردشة مع البائع",
        Buy: "شراء",
        Contact: "تواصل",
        Us: "معنا",
        First_Name: "الاسم الأول",
        Last_Name: "الاسم الأخير",
        Email: "البريد الإلكتروني",
        Phone: "هاتف",
        Comments: "تعليقات",
        Your_message_here: "رسالتك هنا",
        Submit: "إرسال",
        address: "شارع أحمد بن حنبل، حي الريان، الرياض، المملكة العربية السعودية",
        All_Rights_Reserved: "جميع الحقوق محفوظة",
        Designed_Developed_by: "مصمم ومطور بواسطة",
        Fowl: "فاول",
        
        

        // Add more translations here
      },
    },
    // Add more languages as needed
  },
  lng: "English", // Set the default language
  fallbackLng: "English", // Fallback language if a translation is missing
  interpolation: {
    escapeValue: false, // React already escapes values
  },
});

export default i18n;
