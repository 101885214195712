//import IMAGES from "./Images";
import React, { useEffect, useState } from 'react';
import {logo, gPlay, aStore, hero, element1, element2, element3, element4, element5, element6, facebook, twitter, instagram, scroll, about, arrowBtn, element7, listArrow, feature1, feature2, feature3, featureArrowLeft, featureArrowRight, hiw1, hiw2, hiw3, hiw4, hiwArrow, location, call, message, contact, logoFooter, facebookFooter, twitterFooter,  instagramFooter, contactAr} from './Images';

import { Link} from 'react-scroll';
import { useTranslation } from "react-i18next";
import ChangeButton from "./components/ChangeButton";
import { Container, Row, Dropdown } from 'react-bootstrap';
import $ from 'jquery'; 
// import { Link } from "react-router-dom";
// import Form from 'react-bootstrap/Form';
// import Dropdown from 'react-bootstrap/Dropdown';
// import FlagIcon from './FlagIcon.js';
// $(function() {
//     $('.selectpicker').selectpicker();
//     $('pick__lang').selectpicker();
// });

// function go__bahasa() {
//     location = document.pilih__bahasa.ipicked__bahasa.
//     options[document.pilih__bahasa.ipicked__bahasa.selectedIndex].value
// }

function Home() {
    

    // useEffect(() => {
    //     // Initialize the Bootstrap Select plugin
    //     $('.selectpicker').selectpicker();
    //   }, []);

    const { t } = useTranslation();
        const [activeSection, setActiveSection] = useState(null);
      
        useEffect(() => {
          // Function to handle scroll events
          const handleScroll = () => {
            // Calculate the current section based on scroll position
            // You can adjust the offset (50px) as needed
            const sections = document.querySelectorAll('section');
            let currentSection = null;
      
            sections.forEach((section) => {
              const sectionTop = section.offsetTop + 0;
              const sectionBottom = sectionTop + section.clientHeight;
              if (window.scrollY >= sectionTop && window.scrollY < sectionBottom) {
                currentSection = section.id;
              }
            });
      
            setActiveSection(currentSection);
          };
      
          // Add a scroll event listener
          window.addEventListener('scroll', handleScroll);
      
          // Clean up the event listener when the component unmounts
          return () => {
            window.removeEventListener('scroll', handleScroll);
          };
        }, []);
    const [isSticky, setIsSticky] = useState(false);

    useEffect(() => {
        // Function to handle the scroll event
        const handleScroll = () => {
            if (window.scrollY > 1) {
                setIsSticky(true);
            } else {
                setIsSticky(false);
            }
        };

        // Attach the scroll event listener when the component mounts
        window.addEventListener('scroll', handleScroll);

        // Clean up the event listener when the component unmounts
        return () => {
        window.removeEventListener('scroll', handleScroll);
        };
    }, []); // Empty dependency array to run this effect only once when the component mounts

    /*const [countries] = useState([
        { code: 'ua', title: 'Ukraine' },
        { code: 'us', title: 'English' },
        { code: 'pl', title: 'Polish' }
      ]);
      const [toggleContents, setToggleContents] = useState('Select Language');
      const [selectedCountry, setSelectedCountry] = useState();*/
  return (
    <>
    <header className={`${isSticky ? 'sticky' : ''} container-fluid container-fluid-header`}>
    
        <Container className="container">
            <Row className="row">
                <div className="col-12">
                    <div className="header-content">
                        <div className="header-logo">
                            <Link to="/">
							    <img src={logo} alt="Logo"></img>
                            </Link>
                        </div>
                        {/* <nav class="navbar navbar-expand-lg bg-body-tertiary">
                        <div class="container-fluid">
                            <a class="navbar-brand" href="#">Navbar</a>
                            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
                            <span class="navbar-toggler-icon"></span>
                            </button>
                            <div class="collapse navbar-collapse" id="navbarNavAltMarkup">
                            <div class="navbar-nav">
                                <a class="nav-link active" aria-current="page" href="#">Home</a>
                                <a class="nav-link" href="#">Features</a>
                                <a class="nav-link" href="#">Pricing</a>
                                <a class="nav-link disabled" aria-disabled="true">Disabled</a>
                            </div>
                            </div>
                        </div>
                        </nav> */}
                        <nav className="navbar navbar-expand-lg">
                            <div className="collapse navbar-collapse menu_links" id="navbarSupportedContent">
                                <ul className="navbar-nav nav me-auto mb-lg-0">
                                    <li className={`${activeSection === 'section1' ? 'active' : ''} nav-item`}>
                                        <Link to="section1" spy={true} smooth={true} offset={10} duration={500} className="nav-link">{t('Home')}</Link>
                                    </li>
                                    <li className={`${activeSection === 'section2' ? 'active' : ''} nav-item`}>
                                    <Link  to="section2" spy={true} smooth={true} offset={10} duration={500} className="nav-link">{t('About')}</Link>
                                    </li>
                                    <li className={`${activeSection === 'features' ? 'active' : ''} nav-item`}>
                                    <Link  to="features" spy={true} smooth={true} offset={10} duration={500} className="nav-link">{t('Features')}</Link>
                                    </li>
                                    <li className={`${activeSection === 'how-it-works' ? 'active' : ''} nav-item`}>
                                    <Link  to="how-it-works" spy={true} smooth={true} offset={10} duration={500} className="nav-link">{t('How_it_works')}</Link>
                                    </li>
									<li className={`${activeSection === 'contact' ? 'active' : ''} nav-item`}>
                                    <Link  to="contact" spy={true} smooth={true} offset={10} duration={500} className="nav-link">{t('Contact')}</Link>
                                    </li>
                                </ul>
                                
                            </div>
                        </nav>
                        
                        <div className="work-lang-btns">
							
							<div className="select-lang">
                                <ChangeButton />
								
							</div>
						</div>

                        {/* <select className="selectpicker" data-width="fit">
                            <option data-content='<span class="fi fi-us"></span> English'>English</option>
                            <option  data-content='<span class="fi fi-ar"></span> Español'>Español</option>
                        </select> */}
                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                            data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                            aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                    </div>
                </div>
            </Row>
        </Container>
    </header>
    <section className="container-fluid banner-area" id="section1">
        <div className="container banner-container position-relative">
            <div className="row">
                <div className="col-lg-12 header-section-content-wrapper">
                    <div className="header-section-content">
                        <div className="primary-heading">
                            {t('Buy_Sell_Find')}
                        </div>
                        <div className="secondary-heading">
                            {t('just_about_anything_using')}
                        </div>
                        <div className="app-on-heading">
                            <span>{t('app_your_mobile')}</span>
                        </div>
                        <div className="primary-text">
                            {t('lorem_ipsum_1')}
                        </div>
                        <div className="apps-download">
                            <Link to="/">
                                <img src={gPlay} alt="Google Play"></img>
                            </Link>
                            <Link to="/">
                                <img src={aStore} alt="App Store"></img>
                            </Link>
                        </div>
                        <div className="social-hero">
                            <Link to="/">
                                <img src={facebook} alt="facebook"></img>
                            </Link>
                            <Link to="/">
                                <img src={twitter} alt="twitter"></img>
                            </Link>
                            <Link to="/">
                                <img src={instagram} alt="instagram"></img>
                            </Link>
                        </div>
                    </div>
                    <img className="hero-img" src={hero} alt="hero"></img>
                    <img className="element element1" src={element1} alt="Element"></img>
                    <img className="element element2" src={element2} alt="Element"></img>
                    <img className="element element3" src={element3} alt="Element"></img>
                    <img className="element element4" src={element4} alt="Element"></img>
                    <img className="element element5" src={element5} alt="Element"></img>
                    <img className="element element6" src={element6} alt="Element"></img>
                    
                </div>
                <div className="scoller-btn">
                    <img src={scroll} alt="Scroll"></img>
                    <span>{t('Scroll')}</span>
                </div>
            </div>
        </div>
    </section>
    <section className="container-fluid common-section primary-content-wrapper" id="section2">
        <div className="container">
            <div className="row about-row">
                <div className="col-lg-6 about-img-col">
                    <img className="about-img" src={about} alt="about"></img>
                </div>
                <div className="col-lg-6 about-content-col">
                    <div className="primary-heading">
                    {t('All_About_Map')}
                    </div>
                    <div className="secondary-heading">
                    {t('A_Brief_Introduction')}
                    </div>
                    <div className="primary-text primary-text-all">
                        {t('long_established_fact')}
                        <br></br><br></br><br></br>
                        {t('Many_desktop_publishing_packages')}
                    </div>
                    <button className='about-btn'>
                        <span>{t('View_Demo')}</span>
                        <img className="about-arrow-img" src={arrowBtn} alt="Arrow Btn"></img>
                    </button>
                </div>
                <img className="element element7" src={element7} alt="Element"></img>
            </div>
        </div>
    </section>
    <section className="container-fluid common-section primary-content-wrapper" id="features">
        <div className="container">
            <div className="row features-row">
                <div className="primary-heading common-primary-heading text-center">
                    {t('What_makes_us')} <span>{t('unique')}</span> {t('from_others')}
                </div>
                <div className="col-12 col-feature pt-0">
                    <div className="row feature-main-row">
                        <div className="col-lg-6">
                            <div className="secondary-heading">
                            {t('Advanced')} <span>{t('Search')}</span> {t('Filters')}
                            </div>
                            <div className="primary-text primary-text-all">
                                {t('long_established_fact')}
                            </div>
                            <ul className='list-common'>
                                <li>
                                    <img className="listArrow-img" src={listArrow} alt="List Arrow"></img>
                                    <span>{t('advList1')}</span>
                                </li>
                                <li>
                                    <img className="listArrow-img" src={listArrow} alt="List Arrow"></img>
                                    <span>{t('advList2')}</span>
                                </li>
                                <li>
                                    <img className="listArrow-img" src={listArrow} alt="List Arrow"></img>
                                    <span>{t('advList3')}</span>
                                </li>
                                <li>
                                    <img className="listArrow-img" src={listArrow} alt="List Arrow"></img>
                                    <span>{t('advList4')}</span>
                                </li>
                            </ul>
                        </div>
                        <div className="col-lg-6 feature-img-col">
                            <img className="feature-img feature-img-1" src={feature1} alt="feature"></img>
                            <img className="feature-arrow feature-arrow-left" src={featureArrowLeft} alt="Arrow"></img>
                        </div>
                        
                    </div>
                </div>
                <div className="col-12 col-feature">
                    <div className="row feature-main-row feature-row-reverse">
                        <div className="col-lg-6">
                            <div className="secondary-heading">
                                {t('Crazy')} <span>{t('Discounts')}</span>
                            </div>
                            <div className="primary-text primary-text-all">
                                {t('long_established_fact')}
                            </div>
                            <ul className='list-common'>
                                <li>
                                    <img className="listArrow-img" src={listArrow} alt="List Arrow"></img>
                                    <span>{t('advList1')}</span>
                                </li>
                                <li>
                                    <img className="listArrow-img" src={listArrow} alt="List Arrow"></img>
                                    <span>{t('advList2')}</span>
                                </li>
                                <li>
                                    <img className="listArrow-img" src={listArrow} alt="List Arrow"></img>
                                    <span>{t('advList3')}</span>
                                </li>
                                <li>
                                    <img className="listArrow-img" src={listArrow} alt="List Arrow"></img>
                                    <span>{t('advList4')}</span>
                                </li>
                            </ul>
                        </div>
                        <div className="col-lg-6 feature-img-col">
                            <img className="feature-img feature-img-2" src={feature2} alt="feature"></img>
                            <img className="feature-arrow feature-arrow-right" src={featureArrowRight} alt="Arrow"></img>
                        </div>
                        
                    </div>
                </div>
                <div className="col-12 col-feature pb-0">
                    <div className="row feature-main-row">
                        <div className="col-lg-6">
                            <div className="secondary-heading">
                                <span>{t('Notifications')}</span> {t('within_given_radius')}
                            </div>
                            <div className="primary-text primary-text-all">
                                {t('long_established_fact')}
                            </div>
                            <ul className='list-common'>
                                <li>
                                    <img className="listArrow-img" src={listArrow} alt="List Arrow"></img>
                                    <span>{t('advList1')}</span>
                                </li>
                                <li>
                                    <img className="listArrow-img" src={listArrow} alt="List Arrow"></img>
                                    <span>{t('advList2')}</span>
                                </li>
                                <li>
                                    <img className="listArrow-img" src={listArrow} alt="List Arrow"></img>
                                    <span>{t('advList3')}</span>
                                </li>
                                <li>
                                    <img className="listArrow-img" src={listArrow} alt="List Arrow"></img>
                                    <span>{t('advList4')}</span>
                                </li>
                            </ul>
                        </div>
                        <div className="col-lg-6 feature-img-col">
                            <img className="feature-img feature-img-3" src={feature3} alt="feature"></img>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section className="container-fluid common-section primary-content-wrapper" id="how-it-works">
        <div className="container">
            <div className="row how-it-works-row">
                <div className="primary-heading common-primary-heading text-center mb-4">
                    {t('How_it')} <span>{t('Works')}</span>
                </div>
                <div className="primary-text primary-text-hiw text-center">
                    {t('long_established_fact')}
                </div>
                <div className="row process-content-row">
                    <div className="col-lg-3 process-content-col">
                        <div className="process-icon-wrapper bg-blue">
                            <div className="process-icon-inner">
                                <img className="process-icon-1" src={hiw1} alt="How it works icon"/>
                            </div>
                            <img className="process-arrow" src={hiwArrow} alt="Arrow"/>
                        </div>
                        <div className="process-heading process-heading-1">
                        {t('Search_Ad')}
                        </div>
                    </div>
                    <div className="col-lg-3 process-content-col">
                        <div className="process-icon-wrapper bg-red">
                            <div className="process-icon-inner">
                                <img className="process-icon-2" src={hiw2} alt="How it works icon"/>
                            </div>
                            <img className="process-arrow" src={hiwArrow} alt="Arrow"/>
                        </div>
                        <div className="process-heading process-heading-2">
                            {t('Select_Product')}
                        </div>
                    </div>
                    <div className="col-lg-3 process-content-col">
                        <div className="process-icon-wrapper bg-green">
                            <div className="process-icon-inner">
                                <img className="process-icon-3" src={hiw3} alt="How it works icon"/>
                            </div>
                            <img className="process-arrow" src={hiwArrow} alt="Arrow"/>
                        </div>
                        <div className="process-heading process-heading-3">
                            {t('Chat_with_Seller')}
                        </div>
                    </div>
                    <div className="col-lg-3 process-content-col">
                        <div className="process-icon-wrapper bg-orange">
                            <div className="process-icon-inner">
                                <img className="process-icon-4" src={hiw4} alt="How it works icon"/>
                            </div>
                        </div>
                        <div className="process-heading process-heading-4">
                            {t('Buy')}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section className="container-fluid common-section contact-wrapper" id="contact">
        <div className="container">
            <div className="row">
                <div className="col-md-12 col-lg-6 contact-col-first">
                    <form id="contact-us-form">
                        <div className="row">
                            <div className="col-md-12 mb-5">
                                <div className="primary-heading">{t('Contact')} <span>{t('Us')}</span></div>
                            </div>
                            <div className="col-md-12 col-lg-6 form-group">
                                <label>{t('First_Name')}</label>
                                <div className="formInputRow">
                                    <input type="text" name="first_name" className="form-control" placeholder={t("First_Name")}/>
                                </div>
                            </div>
                            <div className="col-md-12 col-lg-6 form-group">
                                <label>{t('Last_Name')}</label>
                                <div className="formInputRow">
                                    <input type="text" name="last_name" className="form-control" placeholder={t('Last_Name')}/>
                                </div>
                            </div>
                            
                            <div className="col-md-12 col-lg-6 form-group">
                                <label>{t('Email')}</label>
                                <div className="formInputRow">
                                    <input type="tel" name="email" className="form-control" placeholder="example@gmail.com"/>
                                </div>
                            </div>
                            <div className="col-md-12 col-lg-6 form-group">
                                <label>{t('Phone')}</label>
                                <div className="formInputRow">
                                    <input type="text" name="phone" className="form-control" placeholder="+966 (0)  |  "/>
                                </div>
                            </div>
                            <div className="col-md-12 form-group">
                                <label>{t('Comments')}</label>
                                <div className="formInputRow">
                                    <textarea name="message_input" className="form-control" id="" cols="30" rows="10" placeholder={t('Your_message_here')}></textarea>
                                </div>

                            </div>

                        </div>
                        <button type="submit" name="submit" id="submit" className="btn btn-primary contact-btn float-end">
                        {t('Submit')}
                        </button>
                    </form>
                </div>
                <div className="col-md-12 col-lg-6 contact-content">
                    <div className="footer-logo mb-5">
                        <img src={logo} alt="Logo"/>
                    </div>
                    <div className="row">
                        <div className="col-md-12 contactDetailRow">
                            <div className="contactDetailIcon">
                                <img src={location} alt="Location Icon"/>
                            </div>
                            <div className="contactDetailText contactDetailTextAddress">
                                {t('address')}
                            </div>
                        </div>
                        <div className="col-md-12 contactDetailRow">
                            <div className="contactDetailIcon contactDetailIconPhone">
                                <img src={call} alt="Call Icon"/>
                            </div>
                            <div className="contactDetailText contactDetailTextPhone">
                                +966 98 765 4321
                            </div>
                        </div>
                        <div className="col-md-12 contactDetailRow">
                            <div className="contactDetailIcon">
                                <img src={message} alt="Meessage Icon"/>
                            </div>
                            <div className="contactDetailText">
                                support@onmap.com
                            </div>
                        </div>
                        
                    </div>
                    <img className='contct-img' src={contact} alt="Contact"/>
                    <img className='contct-img-ar' src={contactAr} alt="Contact"/>
                </div>
            </div>
        </div>
    </section>
    <footer className="container-fluid footer-wrapper">
        <div className="container">
            <div className="row">
                <div className="col-12 footer-content">
                    <div className="footer-content-inner">
                        <div className="footer_logo">
                            <img src={logoFooter} alt='Logo'/>
                        </div>
                        <div className="footer-nav menu_links">

                            <ul>
                                <li className="nav-item  active">
                                    <Link to="section1" spy={true} smooth={true} offset={10} duration={500} className="nav-link">{t('Home')}</Link>
                                </li>
                                <li>
                                    <Link to="section2" spy={true} smooth={true} offset={10} duration={500} className="nav-link">{t('About')}</Link>
                                </li>
                                <li>
                                    <Link to="features" spy={true} smooth={true} offset={10} duration={500} className="nav-link">{t('Features')}</Link>
                                </li>
                                <li>
                                    <Link to="how-it-works" spy={true} smooth={true} offset={10} duration={500} className="nav-link">{t('How_it_works')}</Link>
                                </li>
                                <li>
                                    <Link to="contact" spy={true} smooth={true} offset={10} duration={500} className="nav-link">{t('Contact')}</Link>
                                </li>
                                
                            </ul>
                            <div className="footer-copyright">
                                <div className="copyrightContainer">
                                    <span>&copy; 2023 <b>OnMap</b>. {t('All_Rights_Reserved')}</span> <a href="#!"></a>
                                </div>
                                <div className="developedContainer">
                                    <span>{t('Designed_Developed_by')}</span> 
                                    <Link to="https://fowl.sa" target="_blank">
                                    {t('Fowl')}
                                    </Link>
                                </div>
                            </div>
                        </div>

                        <div class="followContainer">

                            <div class="social-footer">
                                <Link to="/" target="_blank">
                                    <img src={facebookFooter} alt='Logo'/>
                                </Link>
                                <Link to="/" target="_blank">
                                    <img src={twitterFooter} alt='Logo'/>
                                </Link>
                                <Link to="/" target="_blank">
                                    <img src={instagramFooter} alt='Logo'/>
                                </Link>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </footer>
    </>
  );
}

export default Home;
