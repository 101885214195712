import React, { useState } from 'react';
import { useTranslation } from "react-i18next";

import { Dropdown } from 'react-bootstrap';
import { FiSettings, FiUser, FiMail } from 'react-icons/fi'; // Replace with your preferred icons
import {gPlay} from '../Images';


const ChangeButton = () => {
  const { t, i18n } = useTranslation();

  const changeLanguageAndDirection = (lng, dir) => {
    //i18n.changeLanguage(lng);
    i18n.changeLanguage();
    document.documentElement.dir = dir;
    // document.documentElement.dir = (document.documentElement.dir === 'rtl') ? 'ltr':'rtl';
  };

  // const [selectedOption, setSelectedOption] = useState({ label: 'Select Option', icon: <FiSettings /> });

  // // Define the dropdown options
  // const options = [
  //   { label: 'English', icon: <FiSettings />,value: "en,ltr"},
  //   { label: 'Arabic', icon: <FiUser />,value: "ar,rtl" },
  //   // Add more options as needed
  // ];

  // // Function to handle option selection
  // const handleOptionSelect = (option) => {
  //   setSelectedOption(option);
  // };


  //const { t, i18n } = useTranslation();
  // const [selectedOption, setSelectedOption] = useState({ label: t('selectOption'), icon: <FiSettings /> });
  const [selectedOption, setSelectedOption] = useState(
    { 
      label: t('English,ltr'),
      value: ('English'),
       icon: <span class="flag-icon-squared flag-icon-au"></span>
      }
    );
  const options = [
    { label: t('English,ltr'), icon: <span class="flag-icon-squared flag-icon-au"></span>,value:"English" },
    { label: t('العربية,rtl'), icon: <span class="flag-icon-squared flag-icon-sa"></span>,value:"العربية" },
    //{ label: t('inbox'), icon: <FiMail /> },
    //{ label: t('language'), icon: <FiGlobe /> },
  ];

  const handleOptionSelect = (option) => {
    if (option.label === t('language')) {
      // Handle language selection
      return;
      
    }
    if (option.value === t('English')) {
      // Add a class to the body when Option 1 is selected
      document.body.classList.add('direction-ltr');
      document.body.classList.remove('direction-rtl');
    } else if (option.value === t('العربية')) {
      // Remove class1 and add class2 when Option 2 is selected
      document.body.classList.remove('direction-ltr');
      document.body.classList.add('direction-rtl');
    }
    setSelectedOption(option);
  };

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <>
    <Dropdown>
      <Dropdown.Toggle variant="secondary" id="dropdown-lang">
        {selectedOption.icon} {selectedOption.value}
      </Dropdown.Toggle>

      <Dropdown.Menu>
        {options.map((option, index) => (
          <Dropdown.Item
            key={index}
            onClick={() => {
              
              handleOptionSelect(option);
              if (option.label !== t('language')) {
                changeLanguage(i18n.language === 'English' ? 'العربية' : 'English'); // Toggle language between English and Arabic
                const [lng, dir] = option.label.split(',');
                changeLanguageAndDirection(lng, dir);
              }
              
            }}
          >
            {option.icon} {option.value}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
    {/* <Dropdown>
      <Dropdown.Toggle variant="secondary" id="dropdown-basic">
        {selectedOption.icon} {selectedOption.label}
      </Dropdown.Toggle>

      <Dropdown.Menu>
        {options.map((option, index) => (
          <Dropdown.Item key={index} onClick={() => handleOptionSelect(option)}>
            {option.icon} {option.label}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown> */}
    
      {/* <select
        onChange={(e) => {
          //const [lng, dir] = e.target.value.split(",");
          //changeLanguageAndDirection(lng, dir);
        }}
      >
        <option value="en,ltr"><span className="flag-icon flag-icon-us">sdsfsdf</span> English (LTR)</option>
        <option value="ar,rtl">العربية (RTL)</option>
      </select> */}
      
    </>
  );
};

export default ChangeButton;
